<template>
  <v-progress-linear
    background-color="#fff"
    buffer-value="50"
    class="progress"
    :value="val"
    height="15"
    striped
    rounded
  />
</template>
<script>
export default {
  props: ["val"],
  name: "Progress",
};
</script>

<style scoped lang="scss">
.progress {
  width: 100%;
  padding: 0 80px !important;
  border-radius: 20px !important;
  background-color: #ffffff !important;
}
</style>
